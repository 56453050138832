<ion-header [translucent]="true" scrollX="true" scrollY="true">
  <ion-toolbar mode="md">
    <ion-title class="ion-no-margin">
      <ion-text color="primary" class="ion-text-center">
        <h2>Add a Product Detail</h2>
      </ion-text>
    </ion-title>
    <ion-buttons slot="end" mode="ios" class="backButton">
      <ion-button (click)="close()">
        <ion-icon slot="end" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content>
      <ion-progress-bar
        type="indeterminate"
        color="primary"
        *ngIf="loadingDetails"
      >
      </ion-progress-bar>
      <ion-radio-group
        *ngIf="!loadingDetails"
        value="noIcon"
        (ionChange)="setData($event, 'icon')"
      >
        <ion-list-header>
          <ion-label> Select an Icon </ion-label>
        </ion-list-header>

        <ion-item *ngFor="let detail of detailDocs | async">
          <ion-label>
            <img src="{{detail}}" alt="Detail Icon" class="detailIcon" />
          </ion-label>
          <ion-radio slot="start" value="{{detail}}"></ion-radio>
        </ion-item>

        <ion-item>
          <ion-label>No Icon</ion-label>
          <ion-radio slot="start" value="noIcon"></ion-radio>
        </ion-item>
      </ion-radio-group>

      <form [formGroup]="detailForm" (ngSubmit)="addDetail()">
        <ion-item>
          <ion-label position="floating">Detail Text</ion-label>
          <ion-input
            type="text"
            required
            formControlName="text"
            (ionChange)="setData($event, 'text')"
          ></ion-input>
        </ion-item>

        <ion-button
          type="submit"
          fill="outline"
          shape="round"
          color="primary"
          class="ion-margin-vertical"
          [disabled]="!isCurDetailValid"
        >
          Add Detail
        </ion-button>
      </form>
      <br />
      <ion-button
        (click)="submitDetails()"
        type="submit"
        fill="outline"
        shape="round"
        color="primary"
        [disabled]="productDetailInfo.length == 0"
      >
        I'm Done!
      </ion-button>
      <ion-button
        (click)="close()"
        type="submit"
        fill="outline"
        shape="round"
        color="secondary"
      >
        Cancel
      </ion-button>
    </ion-card-content>
  </ion-card>
  <ion-card *ngIf="productDetailInfo.length > 0">
    <ion-card-content>
      <ion-item *ngFor="let detail of productDetailInfo">
        <ion-thumbnail slot="start">
          <img
            *ngIf="detail.iconLink"
            src="{{detail.iconLink}}"
            alt="{{detail.detailName}}"
            class="iconImg"
          />
        </ion-thumbnail>

        <ion-text color="primary">
          <h2>&mdash; {{detail.detailName}}</h2>
        </ion-text>
      </ion-item>
    </ion-card-content>
  </ion-card>
</ion-content>
