// Core+
import { ApplicationRef, Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

// Firebase / AngularFire
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFirePerformance } from '@angular/fire/performance';

// Models
import { LocationDoc } from './models/location.model';

// Services
import { LocalStorageService } from './services/localStorage/local-storage.service';
import { LocationService } from './services/location/location.service';

// Environment
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  curLocation: LocationDoc;
  constructor(
    private update: SwUpdate,
    private appRef: ApplicationRef,
    public perf: AngularFirePerformance,
    public locationService: LocationService,
    public localStorageService: LocalStorageService,
    public afs: AngularFirestore
  ) {
    this.checkUpdate();

    this.locationService.allLocationDocs$.subscribe((docs) => {
      this.localStorageService.locationData$.subscribe((locationData) => {
        try {
          if (locationData.locationId) {
            afs
              .collection(`locations`)
              .doc(locationData.locationId)
              .get()
              .subscribe(
                (
                  locationDoc: firebase.default.firestore.DocumentSnapshot<LocationDoc>
                ) => {
                  this.curLocation = locationDoc.data();
                }
              );
          }
        } catch (error) {}
      });
    });
  }

  async updateClient() {
    // Makes sure that that the update service worker is running (will be false in Dev but should always be true in Prod
    if (!this.update.isEnabled) {
      console.info(`Update SW is not enabled`);
      return;
    }
    // Begin performance metric trace for update time
    const appUpdateTrace = await this.perf.trace('appUpdateTime');

    this.update.available.subscribe(() => {
      appUpdateTrace.start();
      this.update.activateUpdate().then(() => {
        // Define trace path result and close
        if (this.curLocation) {
          appUpdateTrace.putAttribute(
            `location`,
            `${this.curLocation.shortName}`
          );
          appUpdateTrace.putAttribute(`version`, `${environment.appVersion}`);
        } else {
          appUpdateTrace.putAttribute(`location`, `None`);
          appUpdateTrace.putAttribute(`version`, `${environment.appVersion}`);
        }
        appUpdateTrace.stop();

        location.reload();
      });
      console.info(`Updated App`);
      return;
    });
  }

  async checkUpdate() {
    // Makes sure that that the update service worker is running (will be false in Dev but should always be true in Prod)
    if (!this.update.isEnabled) {
      console.info(`Update SW is not enabled`);
      return;
    }
    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        // const timeInterval = interval(30000); //Testing interval (30 seconds)
        const timeInterval = interval(12 * 60 * 60 * 1000); //Prod interval (12 hours)

        timeInterval.subscribe(() => {
          this.update.checkForUpdate();
          this.updateClient();
        });
      }
    });
  }
}
