// Core+
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

// Modal
import { ModalController } from '@ionic/angular';

// Models
import { ProductPrice } from 'src/app/models/flipBook.model';

@Component({
  selector: 'app-product-pricing-builder',
  templateUrl: './product-pricing-builder.page.html',
  styleUrls: ['./product-pricing-builder.page.scss'],
})
export class ProductPricingBuilderPage implements OnInit {
  constructor(
    public viewCtrl: ModalController,
    public formBuilder: FormBuilder
  ) {}

  // Store all the created prices
  productPriceInfo: Array<ProductPrice> = [];

  // Form where data input for each added price will be handled
  priceForm: FormGroup;

  addPrice() {
    // Exit if the price isn't valid
    if (!this.priceForm.valid) {
      window.alert(`This price is invalid, please review it!`);
      return;
    }
    // Store the object
    let curPrice: ProductPrice = {
      price: this.priceForm.controls.price.value.toString(),
    };

    // Format the price to always have 2 digits, no mater what
    const stringPrice: string = curPrice.price.toString();
    const arrPrice: string[] = stringPrice.split('.');
    if (arrPrice[1] === undefined || arrPrice[1].length === 0) {
      curPrice.price = `${stringPrice}.00`;
    }
    if (arrPrice[1] !== undefined && arrPrice[1].length === 1) {
      curPrice.price = `${arrPrice[0]}.${arrPrice[1]}0`;
    }
    if (arrPrice[1] !== undefined && arrPrice[1].length > 2) {
      curPrice.price = `${arrPrice[0]}.${arrPrice[1].substring(0, 2)}`;
    }

    // Append price note to the product if one is present
    if (
      this.priceForm.controls.note.value &&
      this.priceForm.controls.note.value.length > 0
    ) {
      curPrice.note = this.priceForm.controls.note.value;
    }
    // Add the new price to the array of new prices
    this.productPriceInfo.push(curPrice);

    // Reset price form
    this.priceForm.reset();
  }

  submitPrices() {
    this.viewCtrl.dismiss(this.productPriceInfo);
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {
    this.priceForm = this.formBuilder.group({
      price: new FormControl('', [Validators.required, Validators.min(0)]),
      note: new FormControl(''),
    });
  }
}
