import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CategoryEditorPageRoutingModule } from './category-editor-routing.module';

import { CategoryEditorPage } from './category-editor.page';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    CategoryEditorPageRoutingModule,
  ],
  declarations: [CategoryEditorPage],
})
export class CategoryEditorPageModule {}
