// Core+
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Reactive Forms
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';

// AngularFire/Firestore
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

// Services
import { StorageService } from 'src/app/services/storage/storage.service';

// Modal
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

// Model
import { Category } from 'src/app/models/flipBook.model';

@Component({
  selector: 'app-category-editor',
  templateUrl: './category-editor.page.html',
  styleUrls: ['./category-editor.page.scss'],
})
export class CategoryEditorPage implements OnInit {
  // Imported data from caller page
  category: Category;

  // Form config storage
  editCategoryForm: FormGroup;

  // Category file global vars
  destroy$: Subject<null> = new Subject();
  categoryBannerFile: File[];
  uploadPercent: number = 0;

  constructor(
    public viewCtrl: ModalController,
    public navParams: NavParams,
    private readonly storageService: StorageService,
    private formBuilder: FormBuilder,
    private storage: AngularFireStorage,
    private afs: AngularFirestore
  ) {
    this.category = navParams.get('data');
  }

  editCategory() {
    if (this.editCategoryForm.valid) {
      const title = this.editCategoryForm.controls.title.value;
      const subTitle = this.editCategoryForm.controls.subTitle.value;
      const order = this.editCategoryForm.controls.order.value;

      const categoryDocRef: AngularFirestoreDocument = this.afs
        .collection('categories')
        .doc(this.category.docId); // Reference the category doc

      if (this.categoryBannerFile) {
        const mediaFolderPath = `categoryPhotos`;
        const fileToUpload = this.categoryBannerFile[0];

        // Delete old banner image
        this.storage.refFromURL(this.category.imageLink).delete();

        // Upload qr code file and get ref to it's URL
        const { downloadUrl$, uploadProgress$ } =
          this.storageService.uploadFileAndGetMetadata(
            mediaFolderPath,
            fileToUpload
          );

        uploadProgress$
          .pipe(takeUntil(this.destroy$))
          .subscribe((uploadProgress) => {
            if (uploadProgress === 0) {
              this.uploadPercent = 0.01;
            } else {
              this.uploadPercent = uploadProgress / 100;
            }
            if (this.uploadPercent === 1) {
              setTimeout(() => {
                // Reset for next use
                this.editCategoryForm.reset();
                this.uploadPercent = 0;
                this.categoryBannerFile = undefined;
                this.close();
              }, 200);
            }
          });

        // Update changed sections of the documents and upload new QR Code if changed
        downloadUrl$.pipe(takeUntil(this.destroy$)).subscribe((downloadUrl) => {
          categoryDocRef.set(
            {
              title: title,
              subTitle: subTitle,
              order: order,
              imageLink: downloadUrl,
            },
            { merge: true }
          ); // Update the doc
        });
      } else {
        categoryDocRef.set(
          {
            title: title,
            subTitle: subTitle,
            order: order,
          },
          { merge: true }
        ); // Update the doc

        this.editCategoryForm.reset();
        this.uploadPercent = 0;
        this.categoryBannerFile = undefined;
        this.close();
      }
    } else {
      window.alert(
        `An Error was detected, please contact an administrator \n Code: AM-0x004`
      );
    }
  }

  onFileChange(event) {
    try {
      this.categoryBannerFile = event.target.files;
    } catch (err) {
      window.alert(`Couldn't set file. \n ${err}`);
    }
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {
    // Form config and validation for the Edit Category Form
    this.editCategoryForm = this.formBuilder.group({
      title: new FormControl(this.category.title, Validators.required),
      subTitle: new FormControl(this.category.subTitle, [Validators.required]),
      order: new FormControl(this.category.order, [Validators.required]),
      banner: new FormControl(null, [
        Validators.pattern(/^.*\.(SVG|svg|PNG|png|JPG|jpg|JPEG|jpeg)$/g),
      ]),
    });
  }
}
