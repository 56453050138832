// Core+
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

// Modal
import { ModalController } from '@ionic/angular';

// Models
import { AspPrice } from 'src/app/models/asp.model';

@Component({
  selector: 'app-asp-pricing-builder',
  templateUrl: './asp-pricing-builder.page.html',
  styleUrls: ['./asp-pricing-builder.page.scss'],
})
export class AspPricingBuilderPage implements OnInit {
  constructor(
    public viewCtrl: ModalController,
    public formBuilder: FormBuilder
  ) {}

  // Store all the created prices
  aspProductPriceInfo: Array<AspPrice> = [];

  // Form where data input for each added price will be handled
  aspPriceForm: FormGroup;

  addPrice() {
    // Exit if the price isn't valid
    if (!this.aspPriceForm.valid) {
      window.alert(`This price is invalid, please review it!`);
      return;
    }
    // Store the object
    let curPrice: AspPrice = {
      price: this.aspPriceForm.controls.price.value.toString(),
      modelRange: this.aspPriceForm.controls.modelRange.value,
    };

    // Format the price to always have 2 digits, no mater what
    const stringPrice: string = curPrice.price.toString();
    const arrPrice: string[] = stringPrice.split('.');
    if (arrPrice[1] === undefined || arrPrice[1].length === 0) {
      curPrice.price = `${stringPrice}.00`;
    }
    if (arrPrice[1] !== undefined && arrPrice[1].length === 1) {
      curPrice.price = `${arrPrice[0]}.${arrPrice[1]}0`;
    }
    if (arrPrice[1] !== undefined && arrPrice[1].length > 2) {
      curPrice.price = `${arrPrice[0]}.${arrPrice[1].substring(0, 2)}`;
    }

    // Add the new price to the array of new prices
    this.aspProductPriceInfo.push(curPrice);

    // Reset price form
    this.aspPriceForm.reset();
  }

  submitPrices() {
    this.viewCtrl.dismiss(this.aspProductPriceInfo);
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {
    this.aspPriceForm = this.formBuilder.group({
      price: new FormControl('', [Validators.required, Validators.min(0)]),
      modelRange: new FormControl('', Validators.required),
    });
  }
}
