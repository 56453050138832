import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CodeInputModule } from 'angular-code-input';
import { IonicModule } from '@ionic/angular';
import { LocationSelectorPageRoutingModule } from './location-selector-routing.module';
import { LocationSelectorPage } from './location-selector.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CodeInputModule,
    LocationSelectorPageRoutingModule,
  ],
  declarations: [LocationSelectorPage],
})
export class LocationSelectorPageModule {}
