<ion-header scrollX="true" scrollY="true">
  <ion-toolbar>
    <ion-title color="primary">Edit Product: {{product.title}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="true" scrollY="true">
  <ion-card>
    <ion-card-content>
      <!-- Edit Product form -->
      <form [formGroup]="editProductForm" (ngSubmit)="editProduct()">
        <ion-label>Title</ion-label>
        <ion-item>
          <ion-input formControlName="title" type="text" required></ion-input>
        </ion-item>

        <ion-label>Note</ion-label>
        <ion-item>
          <ion-input formControlName="note" type="text"></ion-input>
        </ion-item>

        <ion-label>Order</ion-label>
        <ion-item>
          <ion-input formControlName="order" type="number" required></ion-input>
        </ion-item>

        <ion-button
          fill="outline"
          shape="round"
          [disabled]="!editProductForm.valid"
          type="submit"
        >
          Update Product
        </ion-button>
        <input
          [disabled]="!editProductForm.valid"
          type="submit"
          style="visibility: hidden; position: absolute; left: -1000px"
        />
        <ion-button
          fill="outline"
          shape="round"
          color="dark"
          (click)="this.editProductForm.reset(); close()"
        >
          Cancel
        </ion-button>
      </form>
    </ion-card-content>
  </ion-card>
</ion-content>
