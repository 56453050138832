<ion-header scrollX="true" scrollY="true">
  <ion-toolbar>
    <ion-title color="primary">Edit ASP Plan: {{asp.title}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="true" scrollY="true">
  <ion-card>
    <ion-card-content>
      <!-- Edit Product form -->
      <form [formGroup]="editAspForm" (ngSubmit)="editAsp()">
        <ion-label>Title</ion-label>
        <ion-item>
          <ion-input formControlName="title" type="text" required></ion-input>
        </ion-item>

        <ion-label>Plan Details</ion-label>
        <ion-item>
          <ion-input formControlName="perks" type="text" required></ion-input>
        </ion-item>

        <ion-label>Order</ion-label>
        <ion-item>
          <ion-input formControlName="order" type="number" required></ion-input>
        </ion-item>

        <ion-button
          fill="outline"
          shape="round"
          [disabled]="!editAspForm.valid"
          type="submit"
        >
          Update ASP Plan
        </ion-button>
        <input
          [disabled]="!editAspForm.valid"
          type="submit"
          style="visibility: hidden; position: absolute; left: -1000px"
        />
        <ion-button
          fill="outline"
          shape="round"
          color="dark"
          (click)="this.editAspForm.reset(); close()"
        >
          Cancel
        </ion-button>
      </form>
    </ion-card-content>
  </ion-card>
</ion-content>
