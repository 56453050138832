import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AspEditorPageRoutingModule } from './asp-editor-routing.module';

import { AspEditorPage } from './asp-editor.page';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    AspEditorPageRoutingModule,
  ],
  declarations: [AspEditorPage],
})
export class AspEditorPageModule {}
