// Core+
import { Component, OnInit } from '@angular/core';

// Reactive Forms
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';

// AngularFire/Firestore
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';

// Modal
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

// Model
import { AspTier } from 'src/app/models/asp.model';

@Component({
  selector: 'app-asp-editor',
  templateUrl: './asp-editor.page.html',
  styleUrls: ['./asp-editor.page.scss'],
})
export class AspEditorPage implements OnInit {
  // Imported data from caller page
  asp: AspTier;

  // Form config storage
  editAspForm: FormGroup;

  constructor(
    public viewCtrl: ModalController,
    public navParams: NavParams,
    private formBuilder: FormBuilder,
    private afs: AngularFirestore
  ) {
    this.asp = navParams.get('data');
  }

  editAsp() {
    if (this.editAspForm.valid) {
      const title = this.editAspForm.controls.title.value;
      const perks = this.editAspForm.controls.perks.value;
      const order = this.editAspForm.controls.order.value;

      const aspProductDocRef: AngularFirestoreDocument = this.afs
        .collection('aspProducts')
        .doc(this.asp.docId); // Reference the ASP doc

      aspProductDocRef.set(
        {
          title: title,
          perks: perks,
          order: order,
        },
        { merge: true }
      ); // Update the doc

      this.editAspForm.reset();
      this.close();
    } else {
      window.alert(
        `An Error was detected, please contact an administrator \n Code: AM-0x011`
      );
    }
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {
    // Form config and validation for the Edit ASP Form
    this.editAspForm = this.formBuilder.group({
      title: new FormControl(this.asp.title, Validators.required),
      perks: new FormControl(this.asp.perks, Validators.required),
      order: new FormControl(this.asp.order, [Validators.required]),
    });
  }
}
