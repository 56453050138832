<ion-header scrollX="true" scrollY="true">
  <ion-toolbar>
    <ion-title color="primary">Edit Location: {{location.shortName}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="true" scrollY="true">
  <ion-card>
    <ion-card-content>
      <!-- Edit Location form -->
      <form [formGroup]="editLocationForm" (ngSubmit)="editLocation()">
        <ion-label>Short Name</ion-label>
        <ion-item>
          <ion-input
            formControlName="shortName"
            type="text"
            required
          ></ion-input>
        </ion-item>
        <ion-label>6 Digit Access Pin</ion-label>
        <ion-item>
          <ion-input
            formControlName="pinCode"
            type="number"
            maxlength="6"
            minlength="6"
            required
          ></ion-input>
        </ion-item>
        <ion-label>QR Code SVG File</ion-label>
        <ion-item>
          <ion-input
            formControlName="qrCode"
            (change)="onFileChange($event)"
            type="file"
            accept="image/svg+xml"
          ></ion-input>
        </ion-item>
        <ion-progress-bar
          color="primary"
          [value]="uploadPercent"
          *ngIf="uploadPercent > 0"
        ></ion-progress-bar>
        <ion-button
          fill="outline"
          shape="round"
          [disabled]="!editLocationForm.valid"
          type="submit"
        >
          Update Location
        </ion-button>
        <input
          [disabled]="!editLocationForm.valid"
          type="submit"
          style="visibility: hidden; position: absolute; left: -1000px"
        />
        <ion-button
          fill="outline"
          shape="round"
          color="dark"
          (click)="this.editLocationForm.reset(); close()"
        >
          Cancel
        </ion-button>
      </form>
    </ion-card-content>
  </ion-card>
  <ion-card *ngIf="!this.editLocationForm.controls.qrCode.dirty">
    <ion-card-header>
      <ion-card-title color="primary"> Current QR Code: </ion-card-title>
    </ion-card-header>
    <img
      src="{{location.qrCodeLink}}"
      alt="Existing Location QR code"
      id="qrImg"
    />
  </ion-card>
</ion-content>
