import { Injectable, NgZone } from '@angular/core';
import { User } from '../../models/user.model';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<User>;
  userData: User;

  constructor(
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone
  ) {
    this.user$ = this.ngFireAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afStore.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );

    this.ngFireAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    });
  }

  // Login in with email/password
  async SignIn(email, password) {
    try {
      const credential = await this.ngFireAuth.signInWithEmailAndPassword(
        email,
        password
      );
      if (credential.user.emailVerified) {
        this.SetUserData(credential.user);
        this.router.navigate(['admin']);
      } else {
        window.alert(`Please verify your email address!`);
        await this.SignOut();
      }
    } catch (err) {
      window.alert(err.message);
    }
  }

  // Register user with email/password
  async RegisterUser(email, password, displayName) {
    try {
      const credential = await this.ngFireAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      this.SendVerificationMail();
      await this.SetUserData(credential.user, displayName);
      this.SignOut();
    } catch (err) {
      window.alert(err.message);
    }
  }

  // Email verification when new user register
  async SendVerificationMail() {
    try {
      await (await this.ngFireAuth.currentUser).sendEmailVerification();
    } catch (err) {
      window.alert(err.message);
    }
  }

  // Recover password
  PasswordRecover(passwordResetEmail) {
    try {
      this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail).then(() => {
        if (this.userData.email === passwordResetEmail) {
          this.SignOut();
        }
      });
    } catch (err) {
      window.alert(err.message);
    }
  }

  // Returns true when user is looged in
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null && user.emailVerified !== false ? true : false;
  }

  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.emailVerified !== false ? true : false;
  }

  // Store user in firestore
  SetUserData(user, displayName?) {
    const userRef: AngularFirestoreDocument<User> = this.afStore.doc(
      `users/${user.uid}`
    );
    this.userData = {
      uid: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
    };
    if (displayName) {
      this.userData.displayName = displayName;
    }
    return userRef.set(this.userData, { merge: true });
  }

  // Sign-out
  SignOut() {
    return this.ngFireAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    });
  }

  // Retrieve All User Documents
  get allUserDocs$(): Observable<any> {
    return this.afStore
      .collection<User>('users')
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of Client Forms
          const users: User[] = changes.map((change) => {
            const user: User = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return user;
          });
          return users;
        }),
        // Sort the prizes
        map((users) => {
          return users.sort((a, b) =>
            a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
          );
        })
      );
  }
}
