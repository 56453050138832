<ion-header [translucent]="true" scrollX="true" scrollY="true">
  <ion-toolbar mode="md">
    <ion-title class="ion-no-margin">
      <ion-text color="primary" class="ion-text-center">
        <h2>Extended Warranty</h2>
      </ion-text>
    </ion-title>
    <ion-buttons slot="start" mode="ios" class="backButton">
      <ion-button (click)="close()">
        <ion-icon slot="end" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="true" scrollY="true">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-card>
          <ion-card-content class="ion-text-center">
            <ion-text color="primary">
              <h2>
                Extend your standard 1 year defect warranty to 2 or 3 total
                years with one of our upgrade plans.
                <br />
                Purchase of an extended warranty plan unlocks exclusive ASP plan
                bundle discounts, see details below!
              </h2>
            </ion-text>
            <br />
            <img
              src="../../../assets/QMR-Extended-Warranty-Prices-4.jpg"
              alt="Extended Warranty Plan Info"
              class="infoImg"
            />
            <br />
            <br />
            <h4>
              <ion-text color="primary"> * </ion-text>
              This is an extension of our standard defect warranty and does not
              cover the device against physical or liquid damages. ASP plan
              purchase required for physical damage coverage, sold separately.
            </h4>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
