<ion-header [translucent]="true" scrollX="true" scrollY="true">
  <ion-toolbar mode="md">
    <!-- Used to show the name of the selected ASP plan, this is now shown in the top banner image -->
    <!-- <ion-title class="ion-no-margin">
      <ion-text color="primary" class="ion-text-center">
        <h2>{{aspProductDoc.title}}</h2>
      </ion-text>
    </ion-title> -->
    <ion-buttons slot="end" mode="ios" class="backButton">
      <ion-button (click)="close()">
        <ion-icon slot="end" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="true" scrollY="true" class="ion-padding-horizontal">
  <div class="ion-text-center">
    <img
      class="bannerImg"
      src="{{aspProductDoc.detailLink}}"
      alt="{{aspProductDoc.title}}"
    />
  </div>

  <!-- Used to show the plan details for each ASP plan, this is now shown in the top banner image -->
  <!-- <ion-text color="primary" class="ion-text-center">
    <h3 class="ion-no-margin">
      <ion-text color="secondary">Purchase includes: </ion-text>
      {{aspProductDoc.perks}}
    </h3>
  </ion-text> -->

  <!-- Pricing -->
  <div class="priceOutput centerText">
    <ion-grid>
      <ion-row>
        <ion-col
          size="5"
          *ngFor="let price of aspProductDoc.pricing"
          class="ion-text-center ion-justify-content-center centerText"
        >
          <!-- ngClass is used to dynamically apply one of the 3 classes to the card depending on which tier asp the current one is -->
          <ion-card
            class="ion-no-padding ion-no-margin"
            [ngClass]="{'silvContent': aspProductDoc.title.includes('silver') || aspProductDoc.title.includes('Silver'), 'goldContent' : aspProductDoc.title.includes('gold') || aspProductDoc.title.includes('Gold'), 'platContent' : aspProductDoc.title.includes('platinum') || aspProductDoc.title.includes('Platinum') }"
          >
            <ion-card-content class="ion-no-padding">
              <br />
              <!-- Dynamic color tag is used to make the text black instead of white for the silver tier -->
              <ion-text
                [color]="aspProductDoc.title.includes('silver') || aspProductDoc.title.includes('Silver') ? 'dark' : 'light'"
              >
                <h1 class="centerText text-nowrap priceNoteText">
                  {{price.modelRange}}
                </h1>
              </ion-text>
              <!-- Dynamic color tag is used to make the text black instead of white for the silver tier -->
              <ion-text
                [color]="aspProductDoc.title.includes('silver') || aspProductDoc.title.includes('Silver') ? 'dark' : 'light'"
              >
                <h1 class="centerText priceText">
                  <strong> ${{price.price}} </strong>
                </h1>
                <br />
              </ion-text>
            </ion-card-content>
          </ion-card>
          <br />
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
