<ion-header scrollX="true" scrollY="true">
  <ion-toolbar>
    <ion-title color="primary">Edit Category: {{category.title}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="true" scrollY="true">
  <ion-card>
    <ion-card-content>
      <!-- Edit Category form -->
      <form [formGroup]="editCategoryForm" (ngSubmit)="editCategory()">
        <ion-label>Title</ion-label>
        <ion-item>
          <ion-input formControlName="title" type="text" required></ion-input>
        </ion-item>

        <ion-label>subtitle</ion-label>
        <ion-item>
          <ion-input
            formControlName="subTitle"
            type="text"
            required
          ></ion-input>
        </ion-item>

        <ion-label>Order</ion-label>
        <ion-item>
          <ion-input formControlName="order" type="number" required></ion-input>
        </ion-item>

        <ion-label>Banner Image</ion-label>
        <ion-item>
          <ion-input
            formControlName="banner"
            (change)="onFileChange($event)"
            type="file"
            accept="image/png, image/jpeg, image/svg+xml"
          ></ion-input>
        </ion-item>
        <ion-progress-bar
          color="primary"
          [value]="uploadPercent"
          *ngIf="uploadPercent > 0"
        ></ion-progress-bar>
        <ion-button
          fill="outline"
          shape="round"
          [disabled]="!editCategoryForm.valid"
          type="submit"
        >
          Update Category
        </ion-button>
        <input
          [disabled]="!editCategoryForm.valid"
          type="submit"
          style="visibility: hidden; position: absolute; left: -1000px"
        />
        <ion-button
          fill="outline"
          shape="round"
          color="dark"
          (click)="this.editCategoryForm.reset(); close()"
        >
          Cancel
        </ion-button>
      </form>
    </ion-card-content>
  </ion-card>
  <ion-card *ngIf="!this.editCategoryForm.controls.banner.dirty">
    <ion-card-header>
      <ion-card-title color="primary"> Current Banner Image: </ion-card-title>
    </ion-card-header>
    <img
      src="{{category.imageLink}}"
      alt="Existing Category Banner Image"
      id="bannerImg"
    />
  </ion-card>
</ion-content>
