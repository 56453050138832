<ion-header [translucent]="true" scrollX="true" scrollY="true">
  <ion-toolbar mode="md">
    <ion-title class="ion-no-margin">
      <ion-text color="primary" class="ion-text-center">
        <h2>Select Product Colors</h2>
      </ion-text>
    </ion-title>
    <ion-buttons slot="end" mode="ios" class="backButton">
      <ion-button (click)="close()">
        <ion-icon slot="end" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content>
      <ion-progress-bar
        type="indeterminate"
        color="primary"
        *ngIf="loadingColors"
      ></ion-progress-bar>
      <div *ngIf="!loadingColors">
        <ion-item *ngFor="let color of colorDocs | async">
          <img src="{{color}}" alt="Color Icon" class="colorIcon" />
          <ion-checkbox
            slot="start"
            (ionChange)="updateColorList($event, color)"
          ></ion-checkbox>
        </ion-item>
      </div>

      <ion-button
        (click)="submitColors()"
        type="submit"
        fill="outline"
        shape="round"
        color="primary"
        [disabled]="productColorLinks.length == 0"
      >
        I'm Done!
      </ion-button>
      <ion-button
        (click)="close()"
        type="submit"
        fill="outline"
        shape="round"
        color="secondary"
      >
        Cancel
      </ion-button>
    </ion-card-content>
  </ion-card>
</ion-content>
