// Core+
import { Component, OnInit } from '@angular/core';

// PopOver
import { NavParams, PopoverController } from '@ionic/angular';

// Model
import { Product } from 'src/app/models/flipBook.model';

@Component({
  selector: 'app-ext-warranty-info',
  templateUrl: './ext-warranty-info.page.html',
  styleUrls: ['./ext-warranty-info.page.scss'],
})
export class ExtWarrantyInfoPage implements OnInit {
  productDoc: Product;
  constructor(public navParams: NavParams, public popCtrl: PopoverController) {
    this.productDoc = navParams.get('doc');
  }

  // Closes the popOver
  close() {
    this.popCtrl.dismiss();
  }

  ngOnInit() {}
}
