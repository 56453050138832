import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ExtWarrantyInfoPageRoutingModule } from './ext-warranty-info-routing.module';

import { ExtWarrantyInfoPage } from './ext-warranty-info.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ExtWarrantyInfoPageRoutingModule
  ],
  declarations: [ExtWarrantyInfoPage]
})
export class ExtWarrantyInfoPageModule {}
