// Core+
import { Component, OnInit } from '@angular/core';

// Modal/PopOver
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { ExtWarrantyInfoPage } from '../ext-warranty-info/ext-warranty-info.page';

// Model
import { Product } from 'src/app/models/flipBook.model';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.page.html',
  styleUrls: ['./product-details.page.scss'],
})
export class ProductDetailsPage implements OnInit {
  productDoc: Product;
  constructor(
    public viewCtrl: ModalController,
    public navParams: NavParams,
    public popOverController: PopoverController
  ) {
    this.productDoc = navParams.get('doc');
  }

  // Configure the image viewer
  slideOpts = {
    slidesPerView: 1.3,
    spaceBetween: 5,
    speed: 400,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  async openWarranty(productDoc: Product) {
    const popOver = await this.popOverController.create({
      component: ExtWarrantyInfoPage,
      componentProps: { doc: productDoc },
      cssClass: 'popover-styles',
    });
    return await popOver.present();
  }

  ngOnInit() {}
}
