import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AspInfoPageRoutingModule } from './asp-info-routing.module';

import { AspInfoPage } from './asp-info.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, AspInfoPageRoutingModule],
  declarations: [AspInfoPage],
})
export class AspInfoPageModule {}
