// Core+
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { FlipBookService } from '../../services/flipBook/flip-book.service';

// Modal
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-product-color-selector',
  templateUrl: './product-color-selector.page.html',
  styleUrls: ['./product-color-selector.page.scss'],
})
export class ProductColorSelectorPage implements OnInit {
  // Observable storage for firestore data that is outputted to the page
  colorDocs: Observable<string[]> = this.flipBookService.allColorLinks$;
  loadingColors: boolean = true;

  // Store the selected color icons
  productColorLinks: Array<string> = [];

  constructor(
    public viewCtrl: ModalController,
    public flipBookService: FlipBookService
  ) {}

  submitColors() {
    this.viewCtrl.dismiss(this.productColorLinks);
  }

  updateColorList(event, link) {
    // Adds the color to the array of colors if the matching box is checked
    if (event.detail.checked) {
      this.productColorLinks.push(link);
      return;
    }

    // Removes the color from the array of colors if the matching box is unchecked
    if (!event.detail.checked) {
      // Get the index number of the array element that matches the color that was unchecked
      const linkIndex: number = this.productColorLinks.indexOf(link);
      if (linkIndex === -1) {
        console.info(`No matching element found!`);
        return;
      }
      // Delete the matching link from the array
      this.productColorLinks.splice(linkIndex, 1);
    }
    return;
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {
    // Disable loading bar after data has loaded
    this.colorDocs.subscribe(() => (this.loadingColors = false));
  }
}
