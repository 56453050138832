// Core+
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Models
import { LocationData } from 'src/app/models/location.model';

// Services
import { LocalStorageRefService } from '../localStorageRef/local-storage-ref.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private _localStorage: Storage;

  constructor(private _localStorageRefService: LocalStorageRefService) {
    this._localStorage = _localStorageRefService.localStorage;
  }

  private _locationData$ = new BehaviorSubject<LocationData>(null);
  public locationData$ = this._locationData$.asObservable();

  setInfo(data: LocationData) {
    const jsonData = JSON.stringify(data);
    this._localStorage.setItem('locationData', jsonData);
    this._locationData$.next(data);
  }

  loadInfo() {
    const data = JSON.parse(this._localStorage.getItem('locationData'));
    this._locationData$.next(data);
  }

  clearInfo() {
    this._localStorage.removeItem('locationData');
    this._locationData$.next(null);
  }

  clearAllLocalStorage() {
    this._localStorage.clear();
    this._locationData$.next(null);
  }
}
