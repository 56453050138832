import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ProductEditorModalPageRoutingModule } from './product-editor-routing.module';

import { ProductEditorModalPage } from './product-editor.page';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    ProductEditorModalPageRoutingModule,
  ],
  declarations: [ProductEditorModalPage],
})
export class ProductEditorModalPageModule {}
