import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LocationEditorPageRoutingModule } from './location-editor-routing.module';

import { LocationEditorPage } from './location-editor.page';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    LocationEditorPageRoutingModule,
  ],
  declarations: [LocationEditorPage],
})
export class LocationEditorPageModule {}
