// Core+
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

// Services
import { FlipBookService } from '../../services/flipBook/flip-book.service';

// Modal
import { ModalController } from '@ionic/angular';

// Models
import { ProductDetail } from 'src/app/models/flipBook.model';

@Component({
  selector: 'app-product-details-builder',
  templateUrl: './product-details-builder.page.html',
  styleUrls: ['./product-details-builder.page.scss'],
})
export class ProductDetailsBuilderPage implements OnInit {
  // Observable storage for firestore data that is outputted to the page
  detailDocs: Observable<string[]> = this.flipBookService.allDetailLinks$;
  loadingDetails: boolean = true;

  constructor(
    public viewCtrl: ModalController,
    public flipBookService: FlipBookService,
    public formBuilder: FormBuilder
  ) {}

  // Store all the created details
  productDetailInfo: Array<ProductDetail> = [];

  curDetailIcon: string = 'noIcon';
  curDetailText: string = '';
  isCurDetailValid: boolean = false;

  detailForm: FormGroup;

  setData(event, location) {
    // Add the detail icon link to it's storage var (or the noIcon value in the case of no icon)
    if (location === 'icon') {
      this.curDetailIcon = event.detail.value;
    }
    // Add the name of the detail to it's storage var
    if (location === 'text') {
      this.curDetailText = event.detail.value;
    }
    // Updates the ability to submit the detail based on whether the each of the fields has a proper value
    if (this.curDetailIcon.length > 0 && this.curDetailText.length > 0) {
      this.isCurDetailValid = true;
    } else {
      this.isCurDetailValid = false;
    }
    return;
  }

  addDetail() {
    let curDetail: ProductDetail = {
      detailName: '',
    };
    // Exit if the detail isn't valid
    if (!this.isCurDetailValid) {
      window.alert(`This detail is invalid, please review it!`);
      return;
    }
    // Append data to the detail object depending on whether the new detail should have an icon or not
    if (this.curDetailIcon === 'noIcon') {
      curDetail.detailName = this.curDetailText;
    } else {
      curDetail.iconLink = this.curDetailIcon;
      curDetail.detailName = this.curDetailText;
    }
    // Add the new detail to the array of new details
    this.productDetailInfo.push(curDetail);

    // Reset detail text form
    this.detailForm.reset();
  }

  submitDetails() {
    this.viewCtrl.dismiss(this.productDetailInfo);
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {
    this.detailForm = this.formBuilder.group({
      text: new FormControl('', [Validators.required]),
    });

    // Disable loading bar after data has loaded
    this.detailDocs.subscribe(() => (this.loadingDetails = false));
  }
}
