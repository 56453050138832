// Core+
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Reactive Forms
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';

// AngularFire/Firestore
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

// Services
import { StorageService } from 'src/app/services/storage/storage.service';

// Modal
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

// Model
import { LocationDoc, LocationPin } from '../../models/location.model';

@Component({
  selector: 'app-location-editor',
  templateUrl: './location-editor.page.html',
  styleUrls: ['./location-editor.page.scss'],
})
export class LocationEditorPage implements OnInit {
  // Imported data from caller page
  location: LocationDoc;
  pin: LocationPin;

  // Form config storage
  editLocationForm: FormGroup;

  // Location file global vars
  destroy$: Subject<null> = new Subject();
  qrCodeFile: File[];
  uploadPercent: number = 0;

  constructor(
    public viewCtrl: ModalController,
    public navParams: NavParams,
    private readonly storageService: StorageService,
    private formBuilder: FormBuilder,
    private storage: AngularFireStorage,
    private afs: AngularFirestore
  ) {
    this.location = navParams.get('locationDoc');
    this.pin = navParams.get('pinDoc');
  }

  editLocation() {
    if (this.editLocationForm.valid) {
      const shortName = this.editLocationForm.controls.shortName.value;
      const pinCode = this.editLocationForm.controls.pinCode.value;

      const locationDocRef: AngularFirestoreDocument = this.afs
        .collection('locations')
        .doc(this.location.docId); // Reference the location doc

      const locationPinDocRef: AngularFirestoreDocument = this.afs
        .collection('locationPins')
        .doc(this.pin.docId); // Reference the location pin doc

      locationPinDocRef.set(
        {
          pinCode: pinCode,
        },
        { merge: true }
      ); // Update the doc

      if (this.qrCodeFile) {
        const mediaFolderPath = `locationCodes`;
        const fileToUpload = this.qrCodeFile[0];

        // Delete old QR Code
        this.storage.refFromURL(this.location.qrCodeLink).delete();

        // Upload qr code file and get ref to it's URL
        const { downloadUrl$, uploadProgress$ } =
          this.storageService.uploadFileAndGetMetadata(
            mediaFolderPath,
            fileToUpload
          );

        uploadProgress$
          .pipe(takeUntil(this.destroy$))
          .subscribe((uploadProgress) => {
            if (uploadProgress === 0) {
              this.uploadPercent = 0.01;
            } else {
              this.uploadPercent = uploadProgress / 100;
            }
            if (this.uploadPercent === 1) {
              setTimeout(() => {
                // Reset for next use
                this.editLocationForm.reset();
                this.uploadPercent = 0;
                this.qrCodeFile = undefined;
                this.close();
              }, 200);
            }
          });

        // Update changed sections of the documents and upload new QR Code if changed
        downloadUrl$.pipe(takeUntil(this.destroy$)).subscribe((downloadUrl) => {
          locationDocRef.set(
            {
              shortName: shortName,
              qrCodeLink: downloadUrl,
            },
            { merge: true }
          ); // Update the doc
        });
      } else {
        locationDocRef.set(
          {
            shortName: shortName,
          },
          { merge: true }
        ); // Update the doc

        this.editLocationForm.reset();
        this.uploadPercent = 0;
        this.qrCodeFile = undefined;
        this.close();
      }
    } else {
      window.alert(
        `An Error was detected, please contact an administrator \n Code: AM-0x002`
      );
    }
  }

  onFileChange(event) {
    try {
      this.qrCodeFile = event.target.files;
    } catch (err) {
      window.alert(`Couldn't set file. \n ${err}`);
    }
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {
    // Form config and validation for the Edit Location Form
    this.editLocationForm = this.formBuilder.group({
      shortName: new FormControl(this.location.shortName, Validators.required),
      pinCode: new FormControl(this.pin.pinCode, [
        Validators.required,
        Validators.pattern(/^(?!.*  )(?=.*[\w-])[\w -]{6,6}$/g),
      ]),
      qrCode: new FormControl(null, [Validators.pattern(/^.*\.(SVG|svg)$/g)]),
    });
  }
}
