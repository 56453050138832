// Core+
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Models
import { LocationDoc } from '../../models/location.model';

import { LocalStorageService } from '.././localStorage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(
    private afs: AngularFirestore,
    private localStorageService: LocalStorageService
  ) {
    localStorageService.loadInfo();
  }

  get allLocationDocs$(): Observable<LocationDoc[]> {
    return this.afs
      .collection<LocationDoc>('locations')
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of Client Forms
          const locations: any = changes.map((change) => {
            const location: LocationDoc = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return location;
          });
          return locations;
        }),
        // Sort the locations alphabetically
        map((locations) => {
          return locations.sort((a, b) =>
            a.shortName.toLowerCase() > b.shortName.toLowerCase() ? 1 : -1
          );
        })
      );
  }
}
