import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ProductDetailsBuilderPageRoutingModule } from './product-details-builder-routing.module';

import { ProductDetailsBuilderPage } from './product-details-builder.page';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    ProductDetailsBuilderPageRoutingModule,
  ],
  declarations: [ProductDetailsBuilderPage],
})
export class ProductDetailsBuilderPageModule {}
