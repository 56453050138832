import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AspPricingBuilderPageRoutingModule } from './asp-pricing-builder-routing.module';

import { AspPricingBuilderPage } from './asp-pricing-builder.page';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    AspPricingBuilderPageRoutingModule,
  ],
  declarations: [AspPricingBuilderPage],
})
export class AspPricingBuilderPageModule {}
