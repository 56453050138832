// Core+
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

// Ionic
import {
  AlertController,
  LoadingController,
  ModalController,
  NavParams,
} from '@ionic/angular';

// Timer lib
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';

// Models
import { FirebaseFunctionsResponse } from 'src/app/models/serverResponse.model';

@Component({
  selector: 'app-raffle-winner',
  templateUrl: './raffle-winner.page.html',
  styleUrls: ['./raffle-winner.page.scss'],
})
export class RaffleWinnerPage implements OnInit {
  winnerCountdown: number = 5;

  // Contains some response data and the price winner
  serverResponse: FirebaseFunctionsResponse;

  // Store the code entered in stage 3 for the raffle winner
  winnerCode = '';
  winnerData = {};
  securityCode: number;

  @ViewChild('cd') cd: CountdownComponent;

  constructor(
    public navParams: NavParams,
    public viewCtrl: ModalController,
    public alertController: AlertController,
    public loadingController: LoadingController,
    private functions: AngularFireFunctions
  ) {
    this.serverResponse = navParams.get('doc');
    this.securityCode = navParams.get('securityCode');
  }
  config = {
    leftTime: 5,
    format: 's.S',
  };

  handleEvent(e: CountdownEvent) {
    this.winnerCountdown = e.left;
  }

  skipCountdown() {
    this.config.leftTime = 0;
    this.winnerCountdown = 0;
    return;
  }

  async processWinner(result: string) {
    const loading = await this.loadingController.create({
      message: `Thanks for the feedback,<br />please wait...`,
      backdropDismiss: false,
    });
    await loading.present();

    try {
      const resolveWinnerTicketsCallable = this.functions.httpsCallable(
        'resolveWinnerTickets'
      );
      resolveWinnerTicketsCallable({
        phone: this.serverResponse.data.phone,
        result: result,
        securityCode: this.securityCode,
      }).subscribe(async (res: FirebaseFunctionsResponse) => {
        await loading.dismiss();
        // See if an error code was returned (i.e. the record to modify couldn't be found, etc)
        if (res.code === 0) {
          // Inform the client that there were no errors on the server-side
          const alert = await this.alertController.create({
            header: `Success`,
            message: `
          Information successfully saved,
          Thanks!
          `,
            backdropDismiss: false,
            buttons: [
              {
                text: `OK`,
                handler: () => {
                  alert.dismiss().then(() => this.close());
                },
              },
            ],
          });
          return await alert.present();
        } else {
          // Inform the client that errors occurred during the server call
          const alert = await this.alertController.create({
            header: `Error`,
            message: `
          Information could not be saved.
          <br />
          Error Code: ${res.code}
          `,
            backdropDismiss: false,
            buttons: [
              {
                text: `OK`,
                handler: () => {
                  alert.dismiss().then(() => this.close());
                },
              },
            ],
          });
          return await alert.present();
        }
      });
    } catch (err) {
      await loading.dismiss();
      // Inform the client that errors occurred during the server call
      const alert = await this.alertController.create({
        header: `Error`,
        message: `
        The server couldn't be reached.
        <br />
        Please check your network and try again.
        `,
        backdropDismiss: false,
        buttons: [
          {
            text: `OK`,
            handler: () => {
              alert.dismiss().then(() => this.close());
            },
          },
        ],
      });
      return await alert.present();
    }
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {}
}
