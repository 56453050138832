// Core+
import { Component, OnInit } from '@angular/core';

// Modal
import { AlertController, ModalController, NavParams } from '@ionic/angular';

// Model
import { AspTier } from 'src/app/models/asp.model';

@Component({
  selector: 'app-asp-info',
  templateUrl: './asp-info.page.html',
  styleUrls: ['./asp-info.page.scss'],
})
export class AspInfoPage implements OnInit {
  aspProductDoc: AspTier;
  constructor(
    public viewCtrl: ModalController,
    public navParams: NavParams,
    public alertController: AlertController
  ) {
    this.aspProductDoc = navParams.get('doc');
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {}
}
