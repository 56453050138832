<ion-header [translucent]="true" scrollX="true" scrollY="true">
  <ion-toolbar mode="md">
    <ion-title class="ion-no-margin">
      <ion-text color="primary" class="ion-text-center">
        <h2>{{productDoc.title}}</h2>
      </ion-text>
    </ion-title>
    <ion-buttons slot="start" mode="ios" class="backButton">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" mode="ios" class="warrantyButton">
      <ion-button (click)="openWarranty(productDoc)">
        <ion-icon
          slot="icon-only"
          src="../../../assets/Ext_Warranty_Icon-Red.svg"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="true" scrollY="true">
  <!-- Image viewer/slider -->
  <ion-slides pager="true" [options]="slideOpts">
    <ion-slide class="slide">
      <img src="{{productDoc.primaryImageLink}}" alt="slide-image" />
    </ion-slide>
    <ion-slide *ngFor="let image of productDoc.imageLinks" class="slide">
      <img src="{{image}}" alt="slide-image" />
    </ion-slide>
  </ion-slides>

  <!-- Color Viewer -->
  <ion-grid *ngIf="productDoc.colorLinks" class="ion-margin-top">
    <ion-row>
      <ion-col *ngFor="let colorImage of productDoc.colorLinks">
        <ion-thumbnail class="colorImg">
          <img src="{{colorImage}}" alt="" />
        </ion-thumbnail>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-text color="primary" *ngIf="productDoc.note" class="ion-text-center">
    <h4 class="ion-no-margin">*{{productDoc.note}}*</h4>
  </ion-text>

  <!-- Pricing -->
  <div class="priceOutput">
    <ion-grid>
      <ion-row>
        <ion-col size="6" *ngFor="let price of productDoc.prices">
          <ion-card class="ion-no-padding ion-no-margin">
            <ion-card-content class="ion-no-padding">
              <!-- Seen if the price has a note desccriber -->
              <ion-item *ngIf="price.note" lines="none">
                <h1 class="priceNote" slot="start">{{price.note}}:</h1>
                <ion-text color="primary" slot="end">
                  <h1>${{price.price}}</h1>
                </ion-text>
              </ion-item>
              <!-- Seen if the price doesn't have a note desccriber -->
              <ion-item
                *ngIf="!price.note"
                lines="none"
                class="ion-text-center"
              >
                <ion-text color="primary">
                  <h1>${{price.price}}</h1>
                </ion-text>
              </ion-item>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!-- Details -->
  <ion-list lines="none">
    <div *ngFor="let detail of productDoc.details; let i=index">
      <ion-item>
        <ion-thumbnail slot="start">
          <img
            *ngIf="detail.iconLink"
            src="{{detail.iconLink}}"
            alt="{{detail.detailName}}"
            class="iconImg"
          />
        </ion-thumbnail>

        <ion-text color="primary">
          <h4>&mdash; {{detail.detailName}}</h4>
        </ion-text>
      </ion-item>
    </div>
  </ion-list>
</ion-content>
