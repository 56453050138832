// Core+
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { from, Observable } from 'rxjs';
import { map, switchMap, combineAll } from 'rxjs/operators';

// Models
import { Category, Product } from '../../models/flipBook.model';
import { AspTier } from '../../models/asp.model';

@Injectable({
  providedIn: 'root',
})
export class FlipBookService {
  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  get allCategoryDocs$(): Observable<any> {
    return this.afs
      .collection<Category>('categories')
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of categories
          const categories = changes.map((change) => {
            const category: Category = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return category;
          });
          return categories;
        }),
        // Sort the results
        map((categories) => {
          return categories.sort((a, b) => a.order - b.order);
        })
      );
  }

  productDocs$(categoryId): Observable<Product[]> {
    return this.afs
      .collection<Product>('products')
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of categories
          const products = changes
            // Filter out any products which don't belong to the caller category
            .filter((change) => change.payload.doc.data().catId === categoryId)
            .map((change) => {
              const product: Product = {
                ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
              };
              return product;
            });
          return products;
        }),
        // Sort results by order
        map((products) => {
          return products.sort((a, b) => a.order - b.order);
        })
      );
  }

  aspDocs$(categoryId): Observable<AspTier[]> {
    return this.afs
      .collection<AspTier>('aspProducts')
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of categories
          const aspProducts = changes
            // Filter out any products which don't belong to the caller category
            .filter((change) => change.payload.doc.data().catId === categoryId)
            .map((change) => {
              const aspProduct: AspTier = {
                ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
              };
              return aspProduct;
            });
          return aspProducts;
        }),
        // Sort results by order
        map((aspProducts) => {
          return aspProducts.sort((a, b) => a.order - b.order);
        })
      );
  }

  get allProductDocs$(): Observable<Product[]> {
    return this.afs
      .collection<Product>('products')
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of categories
          const products = changes.map((change) => {
            const product: Product = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return product;
          });
          return products;
        }),
        // Sort results alphabetically
        map((products) => {
          return products.sort((a, b) => a.order - b.order);
        })
      );
  }
  get allAspDocs$(): Observable<AspTier[]> {
    return this.afs
      .collection<AspTier>('aspProducts')
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of categories
          const aspProducts = changes.map((change) => {
            const aspProduct: AspTier = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return aspProduct;
          });
          return aspProducts;
        }),
        // Sort results by order
        map((aspProducts) => {
          return aspProducts.sort((a, b) => a.order - b.order);
        })
      );
  }

  // Output an observable of all the file links in the productColors folder in firebase storage
  get allColorLinks$(): Observable<string[]> {
    // Get a reference to the section of Firebase storage where all of the color icons are stored
    return this.storage
      .ref('productColors')
      .listAll()
      .pipe(
        // After getting observables of each storage object, switch to only return the contents of each observable
        switchMap((colors) => {
          // Get an array of observables where each contains the image link as a string
          const colorLinks$: Observable<string>[] = colors.items.map(
            (color) => {
              // Get the image link as a promise and convert it to an observable
              const colorLink$: Observable<string> = from(
                color.getDownloadURL()
              );
              return colorLink$;
            }
          );

          return colorLinks$;
        }),
        // Combine the output from each string observable in the array into an array of strings in the outer/output observable
        combineAll()
      );
  }

  // Output an observable of all the file links in the detailIcons folder in firebase storage
  get allDetailLinks$(): Observable<string[]> {
    // Get a reference to the section of Firebase storage where all of the detial icons are stored
    return this.storage
      .ref('detailIcons')
      .listAll()
      .pipe(
        // After getting observables of each storage object, switch to only return the contents of each observable
        switchMap((details) => {
          // Get an array of observables where each contains the image link as a string
          const detailLinks$: Observable<string>[] = details.items.map(
            (detail) => {
              // Get the image link as a promise and convert it to an observable
              const detailLink$: Observable<string> = from(
                detail.getDownloadURL()
              );
              return detailLink$;
            }
          );

          return detailLinks$;
        }),
        // Combine the output from each string observable in the array into an array of strings in the outer/output observable
        combineAll()
      );
  }
}
