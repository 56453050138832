<ion-header [translucent]="true" scrollX="true" scrollY="true">
  <ion-toolbar mode="md">
    <ion-buttons slot="end" mode="ios" class="backButton">
      <ion-button (click)="processWinner('winner');close()">
        <ion-icon slot="end" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-no-margin">
      <ion-text color="primary" class="ion-text-center">
        <h2 *ngIf="winnerCountdown > 0">Submitting, Please wait...</h2>
        <h2 *ngIf="winnerCountdown <= 0">Congratulations!</h2>
      </ion-text>
    </ion-title>

    <ion-buttons slot="end" id="assignButton" *ngIf="winnerCountdown > 0">
      <ion-button fill="clear" color="primary" (click)="skipCountdown()">
        <ion-icon slot="icon-only" name="play-forward-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Show countdown and prize winner -->
  <ion-card class="winnerCard">
    <ion-card-content class="ion-text-center ion-justify-content-center">
      <img
        src="../../../assets/loading.gif"
        alt="loading winner"
        *ngIf="winnerCountdown > 0"
      />
      <br />
      <ion-text
        color="primary"
        class="ion-text-center"
        *ngIf="winnerCountdown > 0"
      >
        <countdown
          #cd
          id="countdown"
          (event)="handleEvent($event)"
          [config]="config"
        >
        </countdown>
      </ion-text>

      <div class="winnerInfo" *ngIf="winnerCountdown <= 0">
        <ion-text color="primary">
          <h1 id="congratsText">
            Congratulations
            <strong>
              {{serverResponse.data.fName.charAt(0).toUpperCase()}}{{serverResponse.data.fName.slice(1)}}!
            </strong>
          </h1>
        </ion-text>

        <br />
        <br />
        <h2 id="winnerInfoText">
          The Winner is:
          <ion-text color="primary">
            {{serverResponse.data.fName.charAt(0).toUpperCase()}}{{serverResponse.data.fName.slice(1)}}
            {{serverResponse.data.lName.charAt(0).toUpperCase()}}{{serverResponse.data.lName.slice(1)}}
          </ion-text>
        </h2>
        <h4 id="winnerEmailText">
          Winner's Email: <strong>{{serverResponse.data.email}}</strong>
        </h4>
        <hr />
        <ion-button
          fill="outline"
          color="primary"
          shape="round"
          mode="md"
          (click)="processWinner('winner')"
        >
          Winner
        </ion-button>
        <ion-button
          fill="outline"
          color="secondary"
          shape="round"
          mode="md"
          (click)="processWinner('noShow')"
        >
          Not Present
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
