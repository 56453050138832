import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RaffleWinnerPageRoutingModule } from './raffle-winner-routing.module';

import { RaffleWinnerPage } from './raffle-winner.page';

// Countdown
import { CountdownModule } from 'ngx-countdown';

// Codes
import { CodeInputModule } from 'angular-code-input';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RaffleWinnerPageRoutingModule,
    CodeInputModule,
    CountdownModule,
  ],
  declarations: [RaffleWinnerPage],
})
export class RaffleWinnerPageModule {}
