<ion-header scrollX="true" scrollY="true">
  <ion-toolbar>
    <ion-title *ngIf="!locationName">Register This Kiosk</ion-title>
    <ion-title *ngIf="locationName">
      Register this Kiosk:
      <ion-chip color="primary" outline>
        <ion-icon name="storefront-outline"></ion-icon>
        <ion-label>{{locationName}}</ion-label>
      </ion-chip>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="true" scrollY="true">
  <ion-card *ngIf="!loadingLocations">
    <ion-card-content>
      <ion-text color="primary">
        <h1>Select Location:</h1>
        <br />
      </ion-text>
      <div class="ion-text-center">
        <ion-chip
          *ngFor="let locationDoc of locationDocs | async"
          outline
          color="secondary"
          (click)="pinId = locationDoc.pinId; locationId = locationDoc.docId; locationName = locationDoc.shortName"
          [disabled]="submitting"
        >
          <ion-label>{{locationDoc.shortName}}</ion-label>
        </ion-chip>
      </div>

      <br />

      <ion-item-divider></ion-item-divider>
      <ion-text color="primary">
        <br />
        <h1 *ngIf="!submitting">Enter Access Code:</h1>

        <br />
      </ion-text>
      <code-input
        [isCodeHidden]="true"
        [codeLength]="6"
        (codeCompleted)="onCodeCompleted($event)"
        [disabled]="!pinId"
        *ngIf="!submitting && pinId"
      >
      </code-input>
      <ion-text color="danger" class="ion-text-center">
        <br />
        <h2 *ngIf="!pinId">&mdash; Select a Location &mdash;</h2>
        <ion-spinner *ngIf="submitting"></ion-spinner>
        <br />
      </ion-text>
      <br />
      <ion-button
        fill="outline"
        shape="round"
        color="dark"
        (click)="this.close()"
      >
        Exit
      </ion-button>
      <ion-button
        fill="outline"
        shape="round"
        color="danger"
        (click)="unassignLocation()"
        [disabled]="submitting"
      >
        Un-Assign
      </ion-button>
    </ion-card-content>
  </ion-card>
  <ion-progress-bar
    type="indeterminate"
    color="primary"
    *ngIf="loadingLocations"
  ></ion-progress-bar>
</ion-content>
