// Core+
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

// AngularFire/Firebase
import { AngularFireFunctions } from '@angular/fire/functions';

// Modal
import { AlertController, ModalController } from '@ionic/angular';

// Model
import { LocationDoc, LocationData } from '../../models/location.model';

// Services
import { LocationService } from 'src/app/services/location/location.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.page.html',
  styleUrls: ['./location-selector.page.scss'],
})
export class LocationSelectorPage implements OnInit {
  // Storage vars which will be populated by the HTML when a location is selected
  locationName: string;
  locationId: string;
  pinId: string;

  // Keep track of the time when the pin code is being submitted
  submitting: boolean = false;

  // Ref for all the location docs so that they can be outputted to the HTML
  locationDocs: Observable<LocationDoc[]> =
    this.locationService.allLocationDocs$;
  loadingLocations: boolean = true;

  constructor(
    public viewCtrl: ModalController,
    public alertController: AlertController,
    private functions: AngularFireFunctions,
    private locationService: LocationService,
    private _localStorageService: LocalStorageService
  ) {}

  // Send the code to the server for eval
  onCodeCompleted(code: string) {
    // Update UI to show that we're loading
    this.submitting = true;

    // Ref to Firestore HTTPS function
    const pinCodeAuthCallable = this.functions.httpsCallable('pinCodeAuth');

    // The data that will be sent to the server function
    const requestPacket = {
      pinId: `${this.pinId}`,
      code: `${code}`,
    };

    // Call the server function
    pinCodeAuthCallable(requestPacket).subscribe(async (result: string) => {
      // If the result is that the pins matched then it assigns the kiosk and informs the user
      if (result === 'success') {
        const locationData: LocationData = {
          locationId: this.locationId,
        };
        this._localStorageService.setInfo(locationData);
        // localStorage.setItem('locationId', JSON.stringify(locationData));
        this.completePrompt(
          `Selection Confirmed`,
          `This kiosk sucuessfully been assigned to <strong>${this.locationName}</strong>!`
        );
        this.submitting = false;
        return;
      }
      // Error prompt in the event that the pin code is incorrect
      this.completePrompt(
        `Incorrect Pin`,
        `The pin you've entered for <strong>${this.locationName}</strong> is incorrect, please try again.`
      );
      this.submitting = false;
    });
  }

  async completePrompt(header: string, message: string) {
    const alert = await this.alertController.create({
      header: `${header}`,
      message: `${message}`,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Confirm',
          handler: () => {
            this.close();
          },
        },
      ],
    });

    await alert.present();
  }

  unassignLocation() {
    this._localStorageService.clearInfo();
    this._localStorageService.loadInfo();
    window.location.reload();
    this.close();
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {
    this.locationDocs.subscribe(() => (this.loadingLocations = false));
  }
}
