<ion-header [translucent]="true" scrollX="true" scrollY="true">
  <ion-toolbar mode="md">
    <ion-title class="ion-no-margin">
      <ion-text color="primary" class="ion-text-center">
        <h2>Add a Product Price</h2>
      </ion-text>
    </ion-title>
    <ion-buttons slot="end" mode="ios" class="backButton">
      <ion-button (click)="close()">
        <ion-icon slot="end" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content>
      <form [formGroup]="priceForm" (ngSubmit)="addPrice()">
        <ion-item>
          <ion-label>Price: <ion-text color="primary">$</ion-text></ion-label>
          <ion-input min="0" type="number" required formControlName="price">
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">
            Price Note (storage, cellular, etc)
          </ion-label>
          <ion-input type="text" formControlName="note"></ion-input>
        </ion-item>

        <ion-button
          type="submit"
          fill="outline"
          shape="round"
          color="primary"
          class="ion-margin-vertical"
          [disabled]="!priceForm.valid"
        >
          Add Price
        </ion-button>
      </form>
      <br />
      <ion-button
        (click)="submitPrices()"
        type="submit"
        fill="outline"
        shape="round"
        color="primary"
        [disabled]="productPriceInfo.length == 0"
      >
        I'm Done!
      </ion-button>
      <ion-button
        (click)="close()"
        type="submit"
        fill="outline"
        shape="round"
        color="secondary"
      >
        Cancel
      </ion-button>
    </ion-card-content>
  </ion-card>
  <div class="priceOutput" *ngIf="productPriceInfo.length > 0">
    <ion-card *ngFor="let price of productPriceInfo">
      <ion-card-content>
        <ion-item lines="none">
          <h1 *ngIf="price.note" class="priceNote" slot="start">
            {{price.note}}:
          </h1>
          <ion-text color="primary" slot="end">
            <h1 class="price">${{price.price}}</h1>
          </ion-text>
        </ion-item>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>
