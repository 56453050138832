<ion-header [translucent]="true" scrollX="true" scrollY="true">
  <ion-toolbar mode="md">
    <ion-title class="ion-no-margin">
      <ion-text color="primary" class="ion-text-center">
        <h2>Upload Secondary Product Photos</h2>
      </ion-text>
    </ion-title>
    <ion-buttons slot="end" mode="ios" class="backButton">
      <ion-button (click)="close()">
        <ion-icon slot="end" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content>
      <ion-label>Upload Image (PNG, JPG, or SVG)</ion-label>
      <form [formGroup]="addImageForm" (ngSubmit)="uploadImage()">
        <ion-item>
          <ion-input
            formControlName="image"
            (change)="onProductFileChange($event)"
            type="file"
            accept="image/png, image/jpeg, image/svg+xml"
            required
          ></ion-input>
        </ion-item>
        <ion-button
          type="submit"
          fill="outline"
          shape="round"
          color="primary"
          class="ion-margin-vertical"
          [disabled]="!addImageForm.valid"
        >
          Upload Image
        </ion-button>
      </form>
      <ion-button
        (click)="submitImages()"
        type="submit"
        fill="outline"
        shape="round"
        color="primary"
        [disabled]="secondaryImageLinks.length == 0"
      >
        I'm Done!
      </ion-button>
      <ion-button
        (click)="close()"
        type="submit"
        fill="outline"
        shape="round"
        color="secondary"
      >
        Cancel
      </ion-button>
    </ion-card-content>
  </ion-card>
  <ion-card *ngIf="secondaryImageLinks.length > 0">
    <ion-card-content>
      <img
        *ngFor="let image of secondaryImageLinks"
        src="{{image}}"
        alt=""
        class="imagePreview"
      />
    </ion-card-content>
  </ion-card>
</ion-content>
