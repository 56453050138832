import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Firebase/AngularFire
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
} from '@angular/fire/analytics';
import { AngularFirePerformanceModule } from '@angular/fire/performance';

// Ng Bootstrap (for pagination)
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Modal Pages
import { LocationEditorPageModule } from './modals/location-editor/location-editor.module';
import { LocationSelectorPageModule } from './modals/location-selector/location-selector.module';
import { ProductDetailsPageModule } from './modals/product-details/product-details.module';
import { CategoryEditorPageModule } from './modals/category-editor/category-editor.module';
import { ProductPhotoUploaderPageModule } from './modals/product-photo-uploader/product-photo-uploader.module';
import { ProductColorSelectorPageModule } from './modals/product-color-selector/product-color-selector.module';
import { ProductDetailsBuilderPageModule } from './modals/product-details-builder/product-details-builder.module';
import { ProductPricingBuilderPageModule } from './modals/product-pricing-builder/product-pricing-builder.module';
import { ProductEditorModalPageModule } from './modals/product-editor/product-editor.module';
import { AspInfoPageModule } from './modals/asp-info/asp-info.module';
import { AspEditorPageModule } from './modals/asp-editor/asp-editor.module';
import { AspPricingBuilderPageModule } from './modals/asp-pricing-builder/asp-pricing-builder.module';
import { ExtWarrantyInfoPageModule } from './modals/ext-warranty-info/ext-warranty-info.module';
import { RaffleWinnerPageModule } from './modals/raffle-winner/raffle-winner.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(),
    LocationEditorPageModule,
    LocationSelectorPageModule,
    ProductDetailsPageModule,
    CategoryEditorPageModule,
    ProductPhotoUploaderPageModule,
    ProductColorSelectorPageModule,
    ProductDetailsBuilderPageModule,
    ProductPricingBuilderPageModule,
    ProductEditorModalPageModule,
    AspInfoPageModule,
    AspEditorPageModule,
    AspPricingBuilderPageModule,
    ExtWarrantyInfoPageModule,
    RaffleWinnerPageModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AngularFirestoreModule,
    ScreenTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
