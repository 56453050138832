// Core+
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

// Angular Forms
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

// Services
import { StorageService } from '../../services/storage/storage.service';

// Modal
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';

// Firebase/AngularFire
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-product-photo-uploader',
  templateUrl: './product-photo-uploader.page.html',
  styleUrls: ['./product-photo-uploader.page.scss'],
})
export class ProductPhotoUploaderPage implements OnInit {
  // Product primary image file global vars
  destroyProductImage$: Subject<null> = new Subject();
  productImageFile: File[];
  uploadMainProductPercent: number = 0;

  // Form Config Storage
  addImageForm: FormGroup;

  // Store the uploaded files
  secondaryImageLinks: Array<string> = [];

  constructor(
    public viewCtrl: ModalController,
    private storage: AngularFireStorage,
    private readonly storageService: StorageService,
    private formBuilder: FormBuilder
  ) {}

  uploadImage() {
    if (this.productImageFile !== undefined) {
      const mediaFolderPath = `productPhotos`;
      const fileToUpload = this.productImageFile[0];

      // Upload banner image file and get ref to it's URL
      const { downloadUrl$, uploadProgress$ } =
        this.storageService.uploadFileAndGetMetadata(
          mediaFolderPath,
          fileToUpload
        );

      // Handle UI during upload
      uploadProgress$
        .pipe(takeUntil(this.destroyProductImage$))
        .subscribe((uploadProgress) => {
          if (uploadProgress === 0) {
            this.uploadMainProductPercent = 0.01;
          } else {
            this.uploadMainProductPercent = uploadProgress / 100;
          }
          if (this.uploadMainProductPercent === 1) {
            setTimeout(() => {
              // Reset for next use
              this.addImageForm.reset();
              this.addImageForm = undefined;
              this.addImageForm = this.formBuilder.group({
                image: new FormControl(null, [
                  Validators.required,
                  Validators.pattern(
                    /^.*\.(SVG|svg|PNG|png|JPG|jpg|JPEG|jpeg)$/g
                  ),
                ]),
              });
              this.uploadMainProductPercent = 0;
              this.productImageFile = undefined;
            }, 200);
          }
        });

      // Add category document to firestore with the link to the uploaded file
      downloadUrl$
        .pipe(takeUntil(this.destroyProductImage$))
        .subscribe((downloadUrl) => {
          this.secondaryImageLinks.push(downloadUrl);
        });
    } else {
      window.alert(
        `An Error was detected, please contact an administrator \n Code: AM-0x008`
      );
    }
  }

  submitImages() {
    this.viewCtrl.dismiss(this.secondaryImageLinks);
  }

  onProductFileChange(event) {
    try {
      this.productImageFile = event.target.files;
    } catch (err) {
      window.alert(`Couldn't set file. \n ${err}`);
    }
  }

  // Closes the modal
  close() {
    this.secondaryImageLinks.forEach((secondaryImageLink) =>
      this.storage.refFromURL(secondaryImageLink).delete()
    );
    setTimeout(() => {
      this.viewCtrl.dismiss();
    }, 200);
  }

  ngOnInit() {
    // Form config and validation for the Add secondary product image Form
    this.addImageForm = this.formBuilder.group({
      image: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^.*\.(SVG|svg|PNG|png|JPG|jpg|JPEG|jpeg)$/g),
      ]),
    });
  }
}
