// Core+
import { Component, OnInit } from '@angular/core';

// Reactive Forms
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';

// AngularFire/Firestore
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';

// Modal
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

// Model
import { Product } from 'src/app/models/flipBook.model';

@Component({
  selector: 'app-product-editor',
  templateUrl: './product-editor.page.html',
  styleUrls: ['./product-editor.page.scss'],
})
export class ProductEditorModalPage implements OnInit {
  // Imported data from caller page
  product: Product;

  // Form config storage
  editProductForm: FormGroup;

  constructor(
    public viewCtrl: ModalController,
    public navParams: NavParams,
    private formBuilder: FormBuilder,
    private afs: AngularFirestore
  ) {
    this.product = navParams.get('data');
  }

  editProduct() {
    if (this.editProductForm.valid) {
      const title = this.editProductForm.controls.title.value;
      const note = this.editProductForm.controls.note.value;
      const order = this.editProductForm.controls.order.value;

      const productDocRef: AngularFirestoreDocument = this.afs
        .collection('products')
        .doc(this.product.docId); // Reference the product doc

      productDocRef.set(
        {
          title: title,
          note: note,
          order: order,
        },
        { merge: true }
      ); // Update the doc

      this.editProductForm.reset();
      this.close();
    } else {
      window.alert(
        `An Error was detected, please contact an administrator \n Code: AM-0x009`
      );
    }
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {
    // Form config and validation for the Edit Product Form
    this.editProductForm = this.formBuilder.group({
      title: new FormControl(this.product.title, Validators.required),
      note: new FormControl(this.product.note || ''),
      order: new FormControl(this.product.order, [Validators.required]),
    });
  }
}
